// first palette for New Vegas Casino
$bg-color: #1a1813;
$text-color: #dad9d7;
$primary: #edbc58;
$success: #5CB85C;
$info: #C3A568;
$danger: #D9534F;


$gray-700: mix($bg-color, #fff, 80%); //bs-border-color


#rivalHeaderNavAndLogoSection img {
  margin-top: -10px;
  margin-bottom: -10px;
  max-width: 200px !important;
}


$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;

//menu in primary color
.sticky-sidebar {
  .nav-link:hover, .active-link {
    color: $primary !important;
    .variable-svg-icon {
      background-color: $primary !important;
    }
  }

  .dropdown-item:active {
    background-color: $primary !important;
  }
}


#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;
  }
}

